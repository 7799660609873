import axios from "axios";
import { currentUserStore } from "@/stores/currentUser";
import { User } from "@/interfaces/User";
import tools from "@/composables/tools";

export default function userAuth() {

	async function login(
		u?: string,
		p?: string,
		user_id?: number
	): Promise<any> {
		axios
			.get("login", {
				params: {
					email: u,
					password: p,
					user_id: user_id,
				},
			})
			.then((response) => {
				localStorage.setItem("token", response.data.access_token);
				localStorage.setItem(
					"user",
					JSON.stringify(response.data.user)
				);

				//** guardamo como state los datos del usuario logeado */
				const userJson = localStorage.getItem("user");
				const currentUserObj: User =
					userJson !== null ? JSON.parse(userJson) : {};
				const currentUser = currentUserStore();
				currentUser.update(currentUserObj);
				if (currentUserStore().$state.redirectAfterLogin !== null)
					location.href =
						currentUserStore().$state.redirectAfterLogin + "";
				else location.href = "/";
			})
			.catch((error) => {
				return error.response.status;
			});
	}

	//esta funcion llama a app.galidancia.com con un token de acceso valido
	async function loginGalidancia(user_id: number): Promise<any> {
		axios
			.get("login", {
				params: {
					user_id: user_id,
				},
			})
			.then((response) => {
				console.log('path')
				console.log(process.env.VUE_APP_PATH_GALIDANCIA_APP)
				window.open(
					process.env.VUE_APP_PATH_GALIDANCIA_APP +
						"login?bt=" +
						response.data.access_token
				);
			})
			.catch((error) => {
				return error.response.status;
			});
	}

	function logout(): void {
		tools().setLog("Cerró sesión", "info");
		console.log("Cerrando sesion");
		localStorage.removeItem("token");
		localStorage.removeItem("user");
		location.href = "login";

		//window.location.reload()
	}

	async function checkEmailTlf(email_tlf: string): Promise<any> {
		try {
			const response = await axios.get("checkEmailTlf", {
				params: {
					email: email_tlf,
				},
			});
			return response.data;
		} catch (error) {
			tools().toast(
				"Error en el sistema de autenticación. Consulta con el administrador #145",
				"danger",
				5000
			);
		}
	}

	/*registra a una entidad que no existe como usuario del sistema*/
	async function registerFromEntidad(
		phone_email: string,
		pw: string,
		entidad_id: number
	) {
		try {
			await axios.get("registerFromEntidad", {
				params: {
					email: phone_email,
					pw: pw,
					entidad_id: entidad_id,
				},
			});
			login(phone_email, pw);
		} catch (error) {
			tools().toast(
				"No hemos podido registrarte. Consultao con el administrador #146",
				"danger",
				5000
			);
		}
	}

	/* Devuelve true o false si tiene o no acceso al recurso basandose en los permisos */
	function can(permission: string): boolean {
		try {
			if (currentUserStore().$state.user !== undefined)
				return currentUserStore().$state.user?.permissions.includes(
					permission
				)
					? true
					: false;
			else return false;	
		} catch (error) {
			console.log("Error en la comprobación de permisos");
			return false;
		}

	}

	/* devuelve true o false si hay algun usuario logeado */
	function isLogged(): boolean {
		return localStorage.getItem("user") != "" ? true : false;
	}

	return {
		login,
		logout,
		can,
		isLogged,
		checkEmailTlf,
		registerFromEntidad,
		loginGalidancia,
	};
}
