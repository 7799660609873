<template>
            <ion-menu content-id="main-content">
                        <ion-header>
                        <ion-toolbar>
                            <ion-title>Menú</ion-title>
                        </ion-toolbar>
                        </ion-header>
                        <ion-content class="ion-padding">

                          <ion-accordion-group>
                
                          <ion-menu-toggle>
                            <ion-item href="tabs/mensajes">
                            <ion-label>Mensajes</ion-label>
                          </ion-item>
                          <ion-item href="organizacion/tasks">
                            <ion-label>Tareas</ion-label>
                          </ion-item>
                          <ion-item href="organizacion/tiempos">
                            <ion-label>Tiempos</ion-label>
                          </ion-item>
                          <ion-item href="organizacion/procesos">
                            <ion-label>Procesos</ion-label>
                          </ion-item>
                          <ion-item href="settings/config">
                            <ion-label>Configuración</ion-label>
                          </ion-item>
                        </ion-menu-toggle>


                          <ion-accordion value="financiero">
                            <ion-item slot="header" color="light">
                              <ion-label>Financiero</ion-label>
                            </ion-item>

                            <ion-menu-toggle slot="content">

                              <ion-item href="financiero/transacciones">
                                <ion-label>Transacciones</ion-label>
                              </ion-item>

                              <ion-item href="financiero/proveedores">
                                <ion-label>Proveedores</ion-label>
                              </ion-item>

                              <ion-item href="financiero">
                                <ion-label>Nominas & SEPA</ion-label>
                              </ion-item>

                              <ion-item href="financiero/saldos">
                                <ion-label>Saldos</ion-label>
                              </ion-item>
                            </ion-menu-toggle>

                          </ion-accordion>


                          <ion-accordion value="third">
                            <ion-item slot="header" color="light">
                              <ion-label>Galidancia</ion-label>
                            </ion-item>

                            <ion-menu-toggle slot="content">

                              <ion-item href="galidancia/sesiones">
                                <ion-label>Sesiones</ion-label>
                              </ion-item>

                              <ion-item href="galidancia/clases">
                                <ion-label>Clases</ion-label>
                              </ion-item>

                              <ion-item href="tabs/galidancia">
                                <ion-label>Instalaciones</ion-label>
                              </ion-item>

                              <ion-item href="tabs/cuadromando">
                                <ion-label>Cuadro de mando</ion-label>
                              </ion-item>

                              <ion-item href="tabs/necesidades">
                                <ion-label>Necesidades</ion-label>
                              </ion-item>

                              <ion-item href="galidancia/settings">
                                <ion-label>Configuración</ion-label>
                              </ion-item>
                            </ion-menu-toggle>

                          </ion-accordion>


                        </ion-accordion-group>



                      </ion-content>

                      <ion-label class="ion-text-center" style="margin-bottom: 1em; font-size: 0.9em;">
                        Hola, {{ currentUserStore().$state.user?.name.split(' ')[0] }}
                        <div>Version  {{ require('../../package.json').version }}</div>
                        <ion-icon :src="logOutOutline" @click="userAuth().logout"></ion-icon>
                      </ion-label>
                    </ion-menu>

  </template>
  
  <script lang="ts">
    import { IonAccordionGroup,IonAccordion,IonItem, IonLabel,IonTitle,IonToolbar,IonHeader,IonMenuToggle,IonContent,IonMenu,IonIcon } from '@ionic/vue';
    import { defineComponent,onMounted, ref } from 'vue';
    import { currentUserStore } from '@/stores/currentUser'
    import userAuth from '@/composables/userAuth';
    import { logOutOutline } from 'ionicons/icons';
  
    export default defineComponent({
      components: { IonAccordionGroup, IonAccordion, IonItem, IonLabel,IonTitle,IonToolbar,IonHeader,IonMenuToggle,IonContent,IonMenu,IonIcon},

      setup() {
        const currentUser = currentUserStore()
        const Auth = userAuth()
        const version = ref<string>('');

        onMounted(async () => {
          const packageJson = await import('../../package.json');
          version.value = packageJson.version;
        });


        return {
          currentUser,
          Auth,
          logOutOutline,
          userAuth,
          currentUserStore,
          version
        }
      },
    });
  </script>